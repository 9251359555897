import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Project1 = () => {
  const ref = useRef(null);
  const controls = useAnimation(); // Burada kontrolcüyü tanımlıyoruz
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { t } = useTranslation();
  const { proje2desc, projeTitle2 } = t("home");

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const elementTop = ref.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementTop < windowHeight * 0.9) {
          controls.start({ x: -305 }); // Kontrolcüyü burada kullanıyoruz
        } else {
          controls.start({ x: 500 }); // Kontrolcüyü burada kullanıyoruz
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [controls]);

  return (
    <div className="row container">
      <div className="col-sm-12 col-md-9 pt-4 home-desc order-sm-last order-lg-first">
        <h3 className="project-title">{projeTitle2}</h3>
        <p className="desc">{proje2desc}</p>
      </div>
      <motion.div
        ref={ref}
        animate={controls}
        initial={{ x: isMobile ? 200 : 300 }}
        transition={{ duration: isMobile ? 0.5 : 1.7, ease: "easeOut" }}
        className="col-sm-12 col-md-3 img-container3"
      >
        <img className="h-100" src="/images/Klasemlak.png" alt="" />
      </motion.div>
    </div>
  );
};

export default Project1;
