import { useRef, useState } from "react";
import "./contact.css";
import { motion } from "framer-motion";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const variants = {
  initial: {
    y: 500,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
};

const Contact = () => {
  const ref = useRef();
  const form = useRef();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const { t } = useTranslation();
  const {
    contactTitle,
    firstName,
    lastName,
    mail,
    message,
    submit,
    adress,
    adressDetail,
    sosyalMedia,
    mailus,
    office,
  } = t("contact");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${backendUrl}contact`, formData);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      });
      toast("Mesajınız alınmıştır, en kısa zamanda size geri dönüş yapacağız", {
        icon: "👏",
        style: {
          borderRadius: "10px",
          background: "rgb(81, 42, 173)",
          color: "#fff",
          fontSize: "13px",
        },
      });
      emailjs
        .sendForm("service_jb7fvye", "template_x267068", form.current, {
          publicKey: "H6hH_rJ3gWnkCbIIj",
        })
        .then(
          () => {
            console.log("SUCCESS!");
            setSuccess(true);
          },
          (error) => {
            console.log("FAILED...", error.text);
            setSuccess(false);
          }
        );
    } catch (error) {
      console.log("Form gönderme hatası", error);
      toast.error("Bir şeyler ters gitti, lütfen tekrar deneyiniz!", error);
    }

    console.log(formData);
  };

  return (
    <>
      <Toaster />
      <div className="container contactContainer">
        <motion.div
          ref={ref}
          className="contact row"
          variants={variants}
          initial="initial"
          whileInView="animate"
        >
          <motion.h1 variants={variants}>{contactTitle}</motion.h1>
          <div className="col-12 col-lg-6 order-2 order-lg-1 contactSpans">
            <motion.div className="textContainer row" variants={variants}>
              <div className="col-12">
                <motion.div variants={variants}>
                  <small className="mb-1 adressTitle">{adress}</small>
                  <br />
                  <span className="mb-3 adress adressBody">{adressDetail}</span>
                  <br />
                  <small>{sosyalMedia}</small>
                  <br />
                  <ul className="social-icons social-icons-contact list-inline pb-30">
                    <li className="list-inline-item">
                      <a
                        href="https://facebook.com/profile.php?id=100085283804721"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fa-brands fa-facebook-f fa-lg fs-4"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://www.instagram.com/alohaliveapp/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fa-brands fa-instagram fa-lg fs-4"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://twitter.com/AlohaLiveApptr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fa-brands fa-x-twitter fa-lg fs-4"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        href="https://www.linkedin.com/company/aloha-live-app/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i class="fa-brands fa-linkedin-in fa-lg fs-4"></i>
                      </a>
                    </li>
                  </ul>
                  <small>{mailus}</small>
                  <br />
                  <a
                    href="mailto:info@alohadijital.com"
                    class="bold email-link"
                  >
                    info@alohadijital.com
                  </a>
                </motion.div>
              </div>
            </motion.div>
          </div>
          <motion.div className="col-12 col-lg-6 order-1 order-lg-2 pb-30 position-relative">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1, duration: 1, ease: "easeInOut" }}
              className="formContainer"
            >
              <div className="formBorder">
                <form ref={form} class="fcf-form-class" onSubmit={handleSubmit}>
                  <div class="fcf-form-group">
                    <div class="fcf-input-group">
                      <input
                        type="text"
                        id="Name"
                        name="firstName"
                        class="fcf-form-control"
                        placeholder={firstName}
                        required
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div class="fcf-form-group">
                    <div class="fcf-input-group">
                      <input
                        type="text"
                        id="Soyadi"
                        name="lastName"
                        class="fcf-form-control"
                        placeholder={lastName}
                        required
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div class="fcf-form-group">
                    <div class="fcf-input-group">
                      <input
                        type="email"
                        id="Email"
                        name="email"
                        class="fcf-form-control"
                        placeholder={mail}
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div class="fcf-form-group">
                    <div class="fcf-input-group">
                      <textarea
                        id="Message"
                        name="message"
                        class="fcf-form-control"
                        rows="6"
                        maxlength="3000"
                        placeholder={message}
                        required
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div class="fcf-form-group">
                    <button type="submit" id="fcf-button" class="formButton">
                      {submit}
                    </button>
                  </div>
                </form>
              </div>
            </motion.div>
            <div className="gifContant">
              <motion.img
                initial={{ opacity: 1 }}
                animate={{ opacity: 0 }}
                transition={{ delay: 1, duration: 1, ease: "easeInOut" }}
                src="/images/paper-plane-img.png"
                alt="paper plane"
                loop={false}
                className="paperPlane"
              />
            </div>
          </motion.div>
        </motion.div>
      </div>
      <section className="map-section">
        <div className="container text-center map-container">
          <div className="locaTitleContainer">
            <img
              className="locaIcon"
              src="/images/loca-icon.png"
              alt="location img"
            />
            <h1>{office}</h1>
          </div>

          <iframe
            width="100%"
            height="300px"
            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12044.740737680142!2d29.0965857!3d40.9993198!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x56aaa5919c18edf6!2sKolektif%20House%20Ata%C5%9Fehir!5e0!3m2!1sen!2str!4v1655910357110!5m2!1sen!2str" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            title="Map Location"
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default Contact;
