import styled from "styled-components";
import "./footer.css";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  background: black;
`;

const Left = styled.div`
  /* flex: 1;
  display: flex;
  flex-direction: column; */
  padding: 20px;
  /* text-align: end; */
  display: flex;
  justify-content: end;
  align-items: center;
`;
const Center = styled.div`
  /* flex: 1; */
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Desc = styled.p`
  margin: 20px 0px;
  font-size: 14px;
  color: #c0cbd4;
`;

const SocialContainer = styled.div`
  display: flex;
  padding-left: 24px;
`;
const Icons = styled.i``;

const SocialIcon = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-decoration: none;
  color: white;
  background-color: #${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  transition: background-color 0.6s ease-in-out;
  &:hover {
    background-color: white;
    ${Icons} {
      color: #000;
    }
  }
`;

const Right = styled.div`
  /* flex: 1; */
  padding: 20px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
`;

const Footer = () => {
  const { t } = useTranslation();
  const { reserved, kvkk } = t("footer");
  return (
    <Container className="footer-container">
      <div className="row footer-store">
        <Left className="left col-sm-12 col-md-4 order-3 order-md-1 text-md-left align-self-center">
          <Desc>© 2024 {reserved}</Desc>
        </Left>
        <Center className=" center col-sm-12 col-md-4 order-1 order-md-1 text-md-left align-self-center">
          <SocialContainer>
            <SocialIcon
              href="https://facebook.com/profile.php?id=100085283804721"
              target="_blank"
              rel="noopener noreferrer"
              color="1f2020"
            >
              <Icons className="fa-brands fa-facebook-f fa-lg"></Icons>
            </SocialIcon>
            <SocialIcon
              href="https://www.instagram.com/alohaliveapp/"
              target="_blank"
              rel="noopener noreferrer"
              color="1f2020"
            >
              <Icons className="fa-brands fa-instagram fa-lg fs-4"></Icons>
            </SocialIcon>
            <SocialIcon
              href="https://twitter.com/AlohaLiveApptr"
              target="_blank"
              rel="noopener noreferrer"
              color="1f2020"
            >
              <Icons className="fa-brands fa-x-twitter fa-lg"></Icons>
            </SocialIcon>
            <SocialIcon
              href="https://www.linkedin.com/company/aloha-live-app/"
              target="_blank"
              rel="noopener noreferrer"
              color="1f2020 "
            >
              <Icons className="fa-brands fa-linkedin-in fa-lg"></Icons>
            </SocialIcon>
          </SocialContainer>
        </Center>
        <Right className="right col-sm-12 col-md-4 order-2 order-md-1 text-md-left align-self-center">
          <a
            target="_blank"
            href="/storage/documents/KVKKPolitikası.pdf"
            className="text-decoration-none"
          >
            <Desc className="right-desc">{kvkk}</Desc>
          </a>
        </Right>
      </div>
    </Container>
  );
};

export default Footer;
